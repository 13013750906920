export const environment = {
    name: "preqa",
    production: false,
    rootAuthnUrl: "https://preqa.authn.spectrumreach.io",
    adminPermissionName: "adminUIAccess",
    cognito: {
        customProviderName: "ESSO",
        Auth: {
            region: "us-east-1",
            userPoolId: "us-east-1_FnrvCfFjd",
            userPoolWebClientId: "1su96oip0phf9qmp2u4hfjft6h",
        },
        oauth: {
            domain: "srd-users-qa.auth.us-east-1.amazoncognito.com",
            redirectSignIn: "https://preqa.admin.spectrumreach.io/",
            redirectSignOut: "https://preqa.admin.spectrumreach.io/",
            responseType: "token",
        }
    },
    allowCognitoAuth: true
};