import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export abstract class BaseHttpService {

    constructor(
        protected _httpClient: HttpClient,
        private baseUrl: string
    ) { }

    protected get<T>(route: string, options?: object): Observable<T> {
        return this._httpClient.get<T>(`${this.baseUrl}/${route}`, options)
    }

    protected post<T>(route: string, body: object, options?: object): Observable<T> {
        return this._httpClient.post<T>(`${this.baseUrl}/${route}`, body, options)
    }

    protected put<T>(route: string, body: object, options?: object): Observable<T> {
        return this._httpClient.put<T>(`${this.baseUrl}/${route}`, body, options)
    }

    protected delete<T>(route: string): Observable<T> {
        return this._httpClient.delete<T>(`${this.baseUrl}/${route}`)
    }
}
